.layout {
  position: relative;
  overflow: hidden;
}

.layoutItem {
  position: absolute;
}

.zoomMask {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.zoomMaskClose {
  position: absolute;
  z-index: 999;
  aspect-ratio: 1;
  background-color: #0b3d8b;
  border: solid 1px #1085fd;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translate(-50%, -50%);
  opacity: 0;

  :global {
    img {
      width: 70%;
    }
  }
}

.modalMask {
  opacity: 0;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  opacity: 0;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: scale(0) translate(-50%, -50%);
  transform-origin: 50% 50%;
  align-items: center;
  justify-content: center;
}