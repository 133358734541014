.scrollBoard {
  position: relative;
  flex: 1;
  color: #fff;

  :global {
    .text {
      padding: 0 10px;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .header {
      flex-direction: row;
      font-size: 14px;

      .header-item {
        // .text;
        transition: all 0.3s;
      }
    }

    .rows {
      overflow: hidden;

      .row-item {
        flex-direction: row;
        font-size: 14px;
        transition: all 0.3s;
      }

      .ceil {
        // .text;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .index {
        border-radius: 3px;
        padding: 0px 3px;
      }
    }
  }
}