.page {
  :global {
    .swiper {
      flex: 1;
      width: 100%;
      .swiper-wrapper {
        flex-direction: row;
      }
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      cursor: pointer;
      &.left {
        left: 70px;
      }
      &.right {
        right: 70px;
      }
    }
  }
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.title {
  margin-top: 80px;
  font-size: 64px;
  font-weight: bold;
  z-index: 1;
  text-align: center;
  background: linear-gradient(to bottom, #fff, #6a97fe);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.list {
  display: grid;
  gap: 4rem;
  width: 1406px;
  height: 522px;
  grid-template: 
  "a a a"
  "b b b";
  :global {
    .item {
      width: 426px;
      height: 229px;
      position: relative;
      align-items: center;
      justify-content: center;
      gap: 32px;
      cursor: pointer;
      svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 426px;
        height: 229px;
      }
      .icon {
        z-index: 1;
        // width: 80px;
        height: 82px;
      }
      .name {
        font-size: 34px;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}