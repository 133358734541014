.page {
  align-items: center;
  justify-content: center;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.login {
  z-index: 1;
  border-radius: 16px;
  background-color: #fff;
  flex-direction: row;
  overflow: hidden;

  :global {
    .left {
      justify-content: center;
      align-items: center;
      width: 440px;
      gap: 56px;
      background-image: linear-gradient(45deg,
          #2aa0fb 0%,
          #1a53fc 100%);

      .logo {
        width: 118px;
      }

      .name {
        width: 276px;
        font-size: 34px;
        line-height: 56px;
        color: #ffffff;
        text-align: center;
      }
    }

    .right {
      width: 484px;
      padding: 80px 60px;

      .title {
        font-size: 24px;
        color: #6b9afc;
      }

      .label {
        font-size: 21px;
        color: #333;
        font-weight: bold;
        margin-top: 32px;

        &.account {
          margin-top: 62px;
        }
      }

      input {
        width: 100%;
        height: 49px;
        border-radius: 4px;
        padding: 0 10px;
        border: solid 1px #dddddd;
        outline: none;
        margin-top: 12px;
      }

      .err {
        color: #fff;
        margin-top: 16px;

        &.show {
          color: #f44b35;
        }
      }

      .submit {
        margin-top: 40px;
        height: 49px;
        background-color: #134ffc;
        border-radius: 4px;
        color: #fff;
        font-size: 22px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}